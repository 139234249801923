window._ = require('lodash');

import "mdui/dist/css/mdui.min.css"
import mdui from "mdui";

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
// 定义前后端返回状态码统一处理
import responseCode from "./responseCode";

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    if (response.status == 200) {
        return response.data;
    }
    // 验证错误
    if (response.status == 422) {
        return response.data.msg = "输入参数不真确";
        // return response.data;
    }
    if (response.status == 405) {
        return response.data.msg = "请求方法错误";
    }
    if (response.status == 401) {
        return response.data.msg = "用户未登录";
    }
    return response;
    //
    // // 自定义返回状态返回统一处理
    // let responseData = response.data;
    // switch (responseData.code) {
    //     case 419:
    //         alert("登录失效，请重新登录",function () {
    //             window.location.reload();
    //         });
    //         break;
    //     case 401:
    //         alert("你没有操作权限！");
    //         break;
    //     case responseCode.success || responseCode.layuiGetDataResponseCode: //成功请求返回结果
    //         return responseData;
    //         break;
    //     case responseCode.error: //失败请求统一处理
    //         layer.alert(responseData.msg);
    //         break;
    //     default:
    //         return response;
    //         break;
    // }
}, function (error) {
    // 对响应错误做点什么
    const code = error.response.status;
    const res = error.response.data;
    // 对响应错误做点什么
    switch (code){
        case 401:
            mdui.alert("登录信息失效，请重新登陆！",'提示',function () {
                window.location.href = "/login"
            });
            break;
        case 419:
            return mdui.alert("csrf token mismatch");
            break;
        case 500:
            return mdui.alert("服务器错误");
            break;
        default:
            mdui.alert(res.message,function () {
                window.location.href = "/login"
            });
    }
    return Promise.reject(error);
});


// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
